import React from "react";
import { LinkProvider } from "./src/context/LinkContext";

export function wrapRootElement({ element }) {
  return (
    <>
      <LinkProvider>{element}</LinkProvider>
    </>
  );
}
