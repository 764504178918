import React, { createContext, useReducer, useContext } from "react";

export const LinkContext = createContext(null);

export const LinkDispatchContext = createContext(null);

export function LinkProvider({ children }) {
  const [link, dispatch] = useReducer(linkReducer, initialValues);

  return (
    <LinkContext.Provider value={link}>
      <LinkDispatchContext.Provider value={dispatch}>
        {children}
      </LinkDispatchContext.Provider>
    </LinkContext.Provider>
  );
}

export function useLink() {
  return useContext(LinkContext);
}

export function useLinkDispatch() {
  return useContext(LinkDispatchContext);
}

function linkReducer(state, action) {
  switch (action.type) {
    case "create": {
      console.log("context data", action.data);
      return [
        {
          id: { S: action.data.id },
          link_name: { S: action.data.link_name },
          link_url: { S: action.data.link_url },
          user_id: { S: action.data.user_id },
        },
        ...state,
      ];
      break;
    }

    case "update": {
      return state.map((link) => {
        if (link.id.S === action.data.id.S) {
          return action.data;
        } else {
          return link;
        }
      });
      break;
    }

    case "delete": {
      console.log("ACTION DATA: ", action.data);
      return state.filter((link) => link.id.S !== action.data.S);
      break;
    }

    case "fetch": {
      return action.data;
      break;
    }
    default: {
      throw Error("Unkown action: " + action.type);
    }
  }
}

const initialValues = [{}];
